import React, { useEffect } from 'react'
import Contact from '../Sections-ContactUs/5-Contact'
import OurServices from '../Sections-Services/S1-OurServicesSection'
import WeExistSection from '../Sections-Services/S2-WeExistSection'
import ServicesDisplaySection from '../Sections-Services/S3-ServicesDisplaySection'
import Footer from '../Sections/6-Footer'
import AOS from 'aos'
const Services = (props) => {
  useEffect(() => {
    AOS.init({
     duration: 1000,
     delay:500
    })
    AOS.refresh();
   }, [])
  return (
    <div className="flex overflow-x-hidden">
      <div>
        <OurServices />
        <WeExistSection />
        <ServicesDisplaySection/>
        <Contact />
        <Footer />
      </div>
    </div>
  )
}

export default Services
