import React, { useState } from 'react'
import Modal from 'react-modal/lib/components/Modal'
import ReactPlayer from 'react-player'
import office from '../assets/images/office.jpg'
import trucks from '../assets/videos/trucks.mp4'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const WeExistSection = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  // const videoPath = URL.createObjectURL({ trucks })
  // console.log(videoPath)
  function openModal() {
    setModalIsOpen(true)
  }
  function closeModal() {
    setModalIsOpen(false)
  }
  const modalStyles = {
    content: {
      margin: '2rem',
      background: 'rgba(0,0,0,0.7)'
    }
  }
  return (
    <div className=" md:w-auto font-body " data-aos="fade">
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-center flex-col border-l-0 border-b-0 border-[0.5px] border-lite text-lite pb-16">
          <div className="py-6 w-full text-center mb-6 bg-black bg-opacity-75 h-80 transition-all duration-150 ease-out">
            <h2 className=" font-semibold leading-tight w-full font-subhead xl:text-5xl mb-4">
              We exist to Guide Your Business
            </h2>
            <div className="text-mid h-1/2 text-center bg-opacity-75 transition-all duration-150 ease-out">
              <p className=" px-4 text-center xl:text-xl">
              Look no further if your firm requires a cost-effective solution to assist qualify or maintain its quality management systems. We've assisted hundreds of small and medium-sized businesses across the United States, and we know what it takes to assist you as well.
              </p>
            </div>
          </div>
          {/* <div className="h-36">x</div> */}

          <div className="flex justify-center w-full items-center ">
            <div className="items-center w-[50%] md:w-[30%] drop-shadow-2xl md:-mt-44 xl:-mt-32">
              <LazyLoadImage src={office} alt="" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                onClick={() => openModal()}
                className="absolute w-12 fill-mid top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] cursor-pointer hover:brightness-75 hover:scale-150 hover:animate-spin"
              >
                <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM176 168V344C176 352.7 180.7 360.7 188.3 364.9C195.8 369.2 205.1 369 212.5 364.5L356.5 276.5C363.6 272.1 368 264.4 368 256C368 247.6 363.6 239.9 356.5 235.5L212.5 147.5C205.1 142.1 195.8 142.8 188.3 147.1C180.7 151.3 176 159.3 176 168V168z" />
              </svg>
            </div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={modalStyles}
              className="Modal"
              overlayClassName="Overlay"
            >
              <button
                className="absolute z-50 -right-8 md:top-4 md:right-0"
                onClick={() => closeModal()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  className="w-4 fill-red-500 flex justify-end items-end"
                >
                  <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                </svg>
              </button>
              <ReactPlayer
                url={trucks}
                width="100%"
                height="100%"
                controls={true}
                playing={true}
              />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeExistSection
