import React from 'react'
import googleplay from '../assets/images/googleplay.png'
import appstore from '../assets/images/appstore.png'
import windowsStore from '../assets/images/windows.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const Contact = () => {
  return (
    <div className="font-body md:w-full">
      <div className="flex flex-col h-full ">
        <div className="flex hover:bg-black transition-all duration-150 ease-out hover:bg-opacity-75 flex-col justify-center h-1/5 items-center py-24 border-l-0 border-b-0 border-[0.5px] border-lite text-lite">
          <h2 className="font-bold font-subhead xl:text-5xl mb-4">Contact Us</h2>
          <div className="px-4 text-center xl:text-xl">
            Not all systems are created equal. Contact Synergistic Solutions
            today if your company needs a top-tier quality system that will
            provide you a distinct competitive advantage while also meeting ISO
            certification standards.
          </div>
        </div>
        <div className="hover:bg-black hover:bg-opacity-75 transition-all duration-150 ease-out flex flex-col justify-center items-center h-auto border-l-0 border-[0.5px] py-24 border-lite text-lite">
          <div className="flex flex-col md:flex-row pb-4 cursor-pointer">
            <LazyLoadImage
              src={googleplay}
              alt="GooglePlayStore"
              className="px-2 py-2 md:py-0 w-48 hover:brightness-150 hover:scale-110 transition-all"
            />
            <LazyLoadImage
              src={appstore}
              alt="AppStore"
              className="px-2 py-2 md:py-0 w-48 hover:brightness-150 hover:scale-110 transition-all"
            />
            <LazyLoadImage
              src={windowsStore}
              alt="windowsStore"
              className="px-2 py-2 md:py-0 w-48 hover:brightness-150 hover:scale-110 transition-all"
            />
          </div>
          <div className="">
            <h2 className="text-center font-bold font-subhead xl:text-5xl">
              Learn More
            </h2>
            <div className="px-4 text-center xl:text-xl mt-8">
            Let our consultants show you how to get the most out of your QMS. <br/> Learn more by downloading our app or contacting us
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
