import React from 'react'
import pic from '../assets/images/App3.png'
import browser from '../assets/images/browser.svg'
import comment from '../assets/images/comment.svg'
import eye from '../assets/images/eye-scanner 1.svg'
import phone from '../assets/images/cell-phone.svg'
import tele from '../assets/images/male-telemarketer.svg'
import pen from '../assets/images/vector.svg'
// import Fade from 'react-reveal/Fade'
// import Slide from 'react-reveal/Slide'

const Features = () => {
  return (
    <div>
      {/* for medium screens and above */}
      <div className=" flex-col text-white bg-black bg-opacity-75 pt-16 mt-8 hidden md:flex">
        <div className="flex">
          <div className="flex basis-[20%]"></div>
          {/* <Fade> */}
            <div className="flex basis-[60%] flex-col items-center text-center" data-aos="fade">
              <h2 className="uppercase font-subhead mb-4">App Features</h2>
              <p>
              Our app has been developed following the vision of simplifying all the communication and quality management channels between the service departments. Our Mobile App's main objective is to provide free chat, unlimited features and availibility on popular platforms
              </p>
            </div>
          {/* </Fade> */}
          <div className="flex basis-[20%]"></div>
        </div>
        {/* <Fade> */}
          <div className="mt-8 flex flex-col justify-around items-center" data-aos="fade">
            <img src={comment} alt="" className="w-8" />
            <h4 className="uppercase">full free chat</h4>
            <p className="text-sm text-center">
              All popular features in chat are available <br /> free to use.
            </p>
          </div>
        {/* </Fade> */}
        <div className="flex">
          <div className="flex flex-col basis-[35%] justify-around  text-right ">
            {/* <Slide left> */}
              <div className="flex flex-col items-end" data-aos="slide-right">
                <img src={browser} alt="" className="w-8" />
                <h4 className="uppercase">unlimited features</h4>
                <p className="text-white text-sm">
                  Unlimited features are at your disposal <br />
                  Everything you could dream of
                </p>
              </div>
            {/* </Slide> */}
            {/* <Slide left> */}
              <div className="flex flex-col items-end" data-aos="slide-right">
                <img src={pen} alt="" className="w-8" />
                <h4 className="uppercase">awesome ui design</h4>
                <p className="text-white text-sm">
                The moment you open the app <br />
                you'll feel like you've been using it for years.
                </p>
              </div>
            {/* </Slide> */}
          </div>

          <div className="flex basis-[30%] items-center p-4 justify-center">
            <img src={pic} alt="" />
          </div>

          <div className="flex flex-col basis-[35%] justify-around text-left">
            {/* <Slide right> */}
              <div data-aos="slide-left">
                <img src={phone} alt="" className="w-8" />
                <h4 className="uppercase">ios & android versions</h4>
                <p className="text-white text-sm">
                 Bulit for the latest versions <br />
                 of IOS and Android
                </p>
              </div>
            {/* </Slide> */}
            {/* <Slide right> */}
              <div data-aos="slide-left">
                <img src={eye} alt="" className="w-8" />
                <h4 className="uppercase">retina ready graphics</h4>
                <p className="text-white text-sm">
                  Graphics that give you  <br />
                  the best experience.
                </p>
              </div>
            {/* </Slide> */}
          </div>
        </div>
        {/* <Fade> */}
          <div className="mt-4 flex flex-col justify-around items-center pb-4" data-aos="fade">
            <img src={tele} alt="" className="w-8" />
            <h4 className="uppercase">24/7 support by real people</h4>
            <p className="text-sm text-center">
              24/7 chat support by <br /> our experienced team
            </p>
          </div>
        {/* </Fade> */}
      </div>

      {/* for small screens */}
      <div className="md:hidden flex-col sm:flex text-white bg-black bg-opacity-75 p-4 text-center">
        {/* <Fade> */}
          <h2 className="uppercase font-subhead" data-aos="fade">App Features</h2>
          <p className="mb-8 px-4" data-aos="fade">
          Our app has been developed following the vision of simplifying all the communication and quality management channels between the service departments. Our Mobile App's main objective is to provide free chat, unlimited features and availibility on popular platforms
          </p>
        {/* </Fade> */}
        <img src={pic} alt="" />
        {/* <Slide left> */}
          <div className="flex flex-col justify-center items-center pt-8" data-aos="slide-right">
            <img src={comment} alt="" className="w-8" />
            <h4 className="uppercase">full free chat</h4>
            <p className="text-sm text-center">
              All popular features in chat are available <br /> free to use.
            </p>
          </div>
        {/* </Slide> */}
        {/* <Slide right> */}
          <div className="flex flex-col justify-center items-center pt-8" data-aos="slide-left">
            <img src={browser} alt="" className="w-8" />
            <h4 className="uppercase">unlimited features</h4>
            <p className="text-sm text-center">
            Unlimited features are at your disposal <br />
                  Everything you could dream of
            </p>
          </div>
        {/* </Slide> */}
        {/* <Slide left> */}
          <div className="flex flex-col justify-center items-center pt-8" data-aos="slide-right">
            <img src={phone} alt="" className="w-8" />
            <h4 className="uppercase">ios & android versions</h4>
            <p className="text-sm text-center">
            Bulit for the latest versions <br />
                 of IOS and Android
            </p>
          </div>
        {/* </Slide> */}
        {/* <Slide right> */}
          <div className="flex flex-col justify-center items-center pt-8" data-aos="slide-left">
            <img src={pen} alt="" className="w-8" />
            <h4 className="uppercase">awesome ui design</h4>
            <p className="text-sm text-center">
            The moment you open the app <br />
                you'll feel like you've been using it for years.
            </p>
          </div>
        {/* </Slide> */}
        {/* <Slide left> */}
          <div className="flex flex-col justify-center items-center pt-8" data-aos="slide-right">
            <img src={eye} alt="" className="w-8" />
            <h4 className="uppercase">retina ready graphics</h4>
            <p className="text-sm text-center">
            Graphics that give you  <br />
                  the best experience.
            </p>
          </div>
        {/* </Slide> */}
        {/* <Slide right> */}
          <div className="flex flex-col justify-center items-center pt-8" data-aos="slide-left">
            <img src={tele} alt="" className="w-8" />
            <h4 className="uppercase">24/7 support by real people</h4>
            <p className="text-sm text-center">
            24/7 chat support by <br /> our experienced team
            </p>
          </div>
        {/* </Slide> */}
      </div>
    </div>
  )
}

export default Features
