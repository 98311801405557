import React from 'react'
// import Fade from 'react-reveal/Fade'

import mike from '../assets/images/mike.png'
import '../App.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const CEOQuoteSection = () => {
  return (
    <div className=" md:w-auto flex items-center justify-center flex-col md:flex-row bg-black bg-opacity-75 text-lite mt-36 mb-24 md:my-12">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        className="fill-mid md:w-72 md:-mt-[11rem]  w-28 px-4"
      >
        <path d="M96 224C84.72 224 74.05 226.3 64 229.9V224c0-35.3 28.7-64 64-64c17.67 0 32-14.33 32-32S145.7 96 128 96C57.42 96 0 153.4 0 224v96c0 53.02 42.98 96 96 96s96-42.98 96-96S149 224 96 224zM352 224c-11.28 0-21.95 2.305-32 5.879V224c0-35.3 28.7-64 64-64c17.67 0 32-14.33 32-32s-14.33-32-32-32c-70.58 0-128 57.42-128 128v96c0 53.02 42.98 96 96 96s96-42.98 96-96S405 224 352 224z" />
      </svg>
      <div className="flex justify-center w-full items-center -mt-44 md:-mt-20">
        {/* <Fade> */}
          <div className="items-center w-60 drop-shadow-2xl">
            <LazyLoadImage src={mike} alt="" />
          </div>
        {/* </Fade> */}
      </div>
      <div className="py-6 w-full text-center mb-6  transition-all duration-150 ease-out">
        <h2 className=" font-semibold leading-tight w-full font-subhead text-center ">
          Words From Our CEO
        </h2>
        <div className="text-mid h-1/2 text-center bg-opacity-75 transition-all duration-150 ease-out ">
          <p className=" px-4 text-center ">
          Excellent firms don't believe in excellence - only in constant improvement and constant change.

          </p>
        </div>
      </div>
      {/* <div className="h-36">x</div> */}
    </div>
  )
}

export default CEOQuoteSection
