import React from 'react'
// import { Slide } from 'react-reveal'
import Pic1 from '../assets/images/contact1.png'
import Pic2 from '../assets/images/contact2.png'
import Pic3 from '../assets/images/contact3.png'
import Pic4 from '../assets/images/contact4.png'
// import { LazyLoadImage } from 'react-lazy-load-image-component'

const Discover = () => {
  return (
    <div className="font-body mt-8 mb-16 md:w-auto m-4">
      <div className="flex flex-col ">
        <div className="flex flex-col md:flex-row h-full ">
          <div className="w-full md:w-full flex items-start px-4 justify-center flex-col  text-lite" data-aos="slide-right">
            {/* <h4 className="text-lg">ISO 9001</h4> */}
            <h2 className="font-subhead xl:text-5xl text-center md:text-left">
              DISCOVER US
            </h2>
            <br />
            <p className="xl:text-xl">
              We are here to help you
              <br />
              We've got the answers.
            </p>
            <br />
            <h2 className="font-subhead xl:text-5xl">VISIT US</h2>
            <h4 className="xl:text-xl mt-4">
              <a href="tel:+12148403136">1-214-840-3136</a>
              <br/>
              Irving,
              <br />
              TX, 75061
            </h4>
            <h2 className="font-subhead xl:text-5xl mt-4">EMAIL US</h2>
            <a href="mailto:support@synergisticsol.org">
            <h4 className="xl:text-xl mt-4">support@synergisticsol.org</h4>
            </a>
            <h2 className="font-subhead xl:text-5xl mt-4">
              <a href="tel:+12148403136">CALL US</a>
            </h2>
            <br />
            <br />
          </div>
          <div className="mr-4 w-full md:w-full flex justify-center items-center">
            <div className=" md:mr-4  lg:max-w-[100%] md:w-4/5 md:py-8 align-center text-center relative grid justify-items-center items-center">
              <div className="wrap ">
                <div className="grid grid-rows-8 grid-cols-10">
                  {/* <Slide left> */}
                    <img
                      src={Pic3}
                      alt=""
                      className="row-start-3 row-end-5 col-start-1 col-end-5 z-10"
                      data-aos="fade-right"
                    />
                  {/* </Slide> */}
                  {/* <Slide right> */}
                    <img
                      src={Pic1}
                      alt=""
                      className="row-start-1 row-end-3 col-start-2 col-end-6"
                      data-aos="fade-left"
                    />
                    <img
                      src={Pic2}
                      alt=""
                      className="row-start-2 col-start-7 col-end-11"
                      data-aos="fade-left"
                    />
                  {/* </Slide> */}
                  {/* <Slide left> */}
                    <img
                      src={Pic4}
                      alt=""
                      className="row-start-3 col-start-6 col-end-10"
                      data-aos="fade-right"
                    />
                  {/* </Slide> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Discover
