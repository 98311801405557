import React from 'react'
// import Fade from 'react-reveal/Fade'

import p1 from '../assets/images/1.png'
import p2 from '../assets/images/2.png'
import p3 from '../assets/images/3.png'
import p4 from '../assets/images/4.png'
import p5 from '../assets/images/5.png'
import p6 from '../assets/images/6.png'
import p7 from '../assets/images/7.png'
import p8 from '../assets/images/8.png'

const ServicesDisplaySection = () => {
  return (
    <div className="font-body  md:w-auto mt-12">
      <div className=" flex flex-col md:flex-row h-full ">
        <div className="md:pt-18 md:w-auto flex items-start justify-center flex-col text-lite pb-6 ">
          <div className="flex flex-row relative ">
            <img src={p1} draggable="false" className="contrast-125" alt="" />
            {/* <Fade left> */}
              <div className=" absolute w-1/2 inset-0 text-left flex justify-center items-center flex-col p-4 ml-4" data-aos="fade-right">
                <h2 className="font-subhead text-[1.2rem] md:text-4xl mb-4">
                  ISO 9001:2015
                </h2>
                <p className="text-[6px] sm:text-[12px] hidden md:block leading-tight md:text-[14px] xl:text-xl ">
                  The ISO 9001:2015 standard specifies the requirements for a
                  quality management system. It enables businesses and
                  organizations to be more efficient while also increasing
                  customer satisfaction.
                </p>
              </div>
            {/* </Fade> */}
          </div>

          <div className="flex flex-row relative ">
            {/* <Fade right> */}
              <div className="z-10 absolute text-left w-1/2 flex justify-center items-center flex-col p-4 mr-4 top-0 bottom-0 right-0" data-aos="fade-left">
                <h2 className="font-subhead text-[1.2rem] md:text-4xl mb-4">
                  ISO 14001:2015
                </h2>
                <p className="text-[6px] sm:text-[12px] hidden md:block leading-tight md:text-[14px] xl:text-xl">
                  ISO 14001:2004 is an internationally recognized quality
                  management system that provides a framework for organizations
                  to use when making key decisions about the environmental
                  impact of their operations.
                </p>
              </div>
            {/* </Fade> */}
            <img src={p2} draggable="false" className="contrast-125" alt="" />
          </div>
          <div className="flex flex-row relative ">
            <img src={p3} draggable="false" className="contrast-125" alt="" />
            {/* <Fade left> */}
              <div className="absolute w-1/2 inset-0 text-left flex justify-center items-center flex-col p-4 ml-4 " data-aos="fade-right">
                <h2 className="font-subhead text-[1.2rem] md:text-4xl mb-4">
                  OHSAS 18001
                </h2>
                <p className="text-[6px] sm:text-[12px] hidden md:block leading-tight md:text-[14px] xl:text-xl ">
                  OHSAS 18001 places a proactive and preventative emphasis on
                  risk-control factors in the workplace by identifying and
                  assessing the likelihood and severity of hazards. Along with
                  quality, environmental, and other management systems, it is an
                  essential management system for your company
                </p>
              </div>
            {/* </Fade> */}
          </div>

          <div className="flex flex-row relative ">
            {/* <Fade right> */}
              <div className="z-10 absolute w-1/2  text-left   flex justify-center items-center flex-col p-4 mr-4 top-0 bottom-0 right-0" data-aos="fade-left">
                <h2 className="font-subhead text-[1.2rem] md:text-4xl mb-4">
                  ISO 45001:2018
                </h2>
                <p className="text-[6px] sm:text-[12px] hidden md:block leading-tight md:text-[14px] xl:text-xl">
                  ISO 45001 is a global standard for Occupational Health and
                  Safety Management Systems that provides a realistic solution
                  to improve employee and other personnel safety and health. It
                  is a standard that may be applied to any firm, regardless of
                  size, type, or nature.
                </p>
              </div>
            {/* </Fade> */}
            <img src={p4} draggable="false" className="contrast-125" alt="" />
          </div>
          <div className="flex flex-row relative ">
            <img src={p5} draggable="false" className="contrast-125" alt="" />
            {/* <Fade left> */}
              <div className=" absolute w-1/2 inset-0 text-left flex justify-center items-center flex-col p-4 ml-4 " data-aos=" fade-right">
                <h2 className="font-subhead text-[1.2rem] md:text-4xl mb-4">
                  Outsourced Internal Auditing
                </h2>
                <p className="text-[5.5px] hidden md:block leading-tight sm:text-[12px] md:text-[14px] xl:text-xl " >
                  Outsourcing internal audits is the answer that all
                  ISO-certified companies should use. Internal employees have
                  jobs to accomplish, do not identify problems before the
                  external auditor, and do not drive progress. Outsourced
                  internal auditors not only protect your certification, but are
                  also ISO experts. They should be coaching, training, and
                  assisting while auditing.
                </p>
              </div>
            {/* </Fade> */}
          </div>

          <div className="flex flex-row relative ">
            {/* <Fade right> */}
              <div className="z-10 absolute w-1/2 text-left flex justify-center items-center flex-col p-4 mr-4 top-0 bottom-0 right-0" data-aos="fade-left">
                <h2 className="font-subhead text-[1.2rem] md:text-4xl mb-4 ">
                  R2:2013
                </h2>
                <p className="text-[6px] sm:text-[12px] hidden md:block leading-tight md:text-[14px] xl:text-xl ">
                  The R2:2013 standard requires Interconnection to adhere to the
                  highest industry standards and best practices in areas such as
                  data security, environmental responsibility in the United
                  States and overseas, operations and safety, accountability,
                  and monitoring e-waste to its final destination.
                </p>
              </div>
            {/* </Fade> */}
            <img src={p6} draggable="false" className="contrast-125 w-[100vw]" alt="" />
          </div>
          <div className="flex flex-row relative ">
            <img src={p7} draggable="false" className="contrast-125" alt="" />
            {/* <Fade left> */}
              <div className=" absolute w-1/2 inset-0 text-left   flex justify-center items-center flex-col p-4 ml-4 " data-aos="fade-right">
                <h2 className="font-subhead text-[1.2rem] md:text-4xl mb-4">
                  Supplier Audits
                </h2>
                <p className="text-[6px] sm:text-[12px] hidden md:block leading-tight md:text-[14px] xl:text-xl ">
                  Supplier audits can be conducted to a number of international
                  standards, your in-house policies or Synergistic's own
                  Best-in-Class protocol. We can audit your existing suppliers
                  or ones you are contemplating for inclusion into your supply
                  chain.
                </p>
              </div>
            {/* </Fade> */}
          </div>

          <div className="flex flex-row relative ">
            {/* <Fade right> */}
              <div className="z-10 absolute w-1/2  text-left   flex justify-center items-center flex-col p-4 mr-4 top-0 bottom-0 right-0" data-aos="fade-left">
                <h2 className="font-subhead text-[1.2rem] md:text-4xl mb-4">
                  Second Party Audits
                </h2>
                <p className="text-[6px] sm:text-[12px] hidden md:block leading-tight md:text-[14px] xl:text-xl">
                  Second party or supplier auditing systems that are well
                  planned and performed are particularly useful for evaluating
                  potential future suppliers and controlling the quality of
                  current supplier components and assemblies.
                </p>
              </div>
            {/* </Fade> */}
            <img src={p8} draggable="false" className="contrast-125" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesDisplaySection
