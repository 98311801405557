
export const NavNames = [
  { name: 'Services',link: '/services'},
  { name: 'About Us', link: '/aboutus' },
  { name: 'Contact Us', link: '/contactus' },
  { name: 'App', link: '/app' }
]

export const TableData = [
  {
    service:"ISO 9001:2015",
    image:"iso_9001_2005",
    content:"The ISO 9001:2015 standard specifies the requirements for a quality management system."
  },
  {
    service:"ISO 14001:2015",
    image:"",
    content:"ISO 14001:2004 is an internationally recognized quality management system."
  },
  {
    service:"OHSAS 18001",
    image:"",
    content:"OHSAS 18001 places a proactive and preventative emphasis on risk-control factors."
  },
  {
    service:"ISO 45001:2018",
    image:"",
    content:"ISO 45001 is a global standard for Occupational Health and Safety Management Systems."
  },
  {
    service:"Outsourced Internal Auditing",
    image:"",
    content:"Outsourcing internal audits is the answer that allISO-certified companies should use."
  },
  {
    service:"R2:2013",
    image:"",
    content:"The R2:2013 standard requires Interconnection to adhere to the highest industry standards."
  },
  {
    service:"Supplier Audits",
    image:"",
    content:"Supplier audits can be conducted to a number of international standards."
  },
  {
    service:"Second Party Audits",
    image:"",
    content:"Second party or supplier auditing systems that are well planned and performed."
  },
]