import React from 'react'
import '../App.css'
import googleplay from '../assets/images/googleplay.png'
import appstore from '../assets/images/appstore.png'
import windowsStore from '../assets/images/windows.png'


const Contact = () => {
  return (
    <div className="font-body md:w-full">
      <div className="flex flex-col h-full ">
        <div className="flex hover:bg-black transition-all duration-150 ease-out hover:bg-opacity-75 flex-col justify-center h-1/5 items-center py-24 border-l-0 border-b-0 border-[0.5px] border-lite text-lite">
          <h2 className="font-bold font-subhead xl:text-5xl mb-4">Contact Us</h2>
          <div className="px-4 text-center xl:text-xl">
          Look no further if your firm requires a cost-effective solution
                to assist qualify or maintain its quality management systems.
                We've assisted hundreds of small and medium-sized businesses
                across the United States, and we know what it takes to assist
                you as well.
          </div>
        </div>
        <div className="hover:bg-black hover:bg-opacity-75 transition-all duration-150 ease-out flex flex-col justify-center items-center h-auto border-l-0 border-[0.5px] py-24 border-lite text-lite">
          <div className="flex flex-col  md:flex-row pb-4 cursor-pointer">
            <img
              src={googleplay}
              alt="GooglePlayStore"
              className="px-2 py-2 md:py-0 w-48 hover:brightness-150 hover:scale-110"
            />
            <img
              src={appstore}
              alt="AppStore"
              className="px-2 w-48 py-2 md:py-0 hover:brightness-150 hover:scale-110"
            />
            <img
              src={windowsStore}
              alt="AppStore"
              className="px-2 w-48 py-2 md:py-0 hover:brightness-150 hover:scale-110"
            />
          </div>
          <div className="">
            <h2 className="text-center font-bold font-subhead xl:text-5xl">
              Learn More
            </h2>
            <div className="px-4 text-center xl:text-xl mt-8">
            Let our consultants show you how to get the most out of your QMS. <br/> Learn more by downloading our app or contacting us
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
