import React from 'react'
import phone from '../assets/images/app1.png'
// import soon from '../assets/images/app-coming-soon.png'
import GooglePlay from '../assets/images/googleplay.png'
import AppStore from '../assets/images/appstore.png'
import windowsStore from '../assets/images/windows.png'

import { Link } from 'react-router-dom'
import Button from '../Components/button'

const AppFrontSection = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col lg:flex-row text-white p-4 mt-8">
        <div className="flex basis-[10%]"></div>
        <div
          className=" flex flex-col basis-[30%] justify-center gap-y-6"
          data-aos="fade-right"
        >
          <h2 className="font-head text-5xl sm:text-6xl md:text-7xl lg:text-7xl mr-2 text-lite leading-tight text-center md:text-left">
            A great app will make <br /> your life better
          </h2>
          <p className="xl:text-xl">
            Quality Management software streamlines audit-related tasks The
            software makes it easy to manage any type of audit's activities,
            data and processes, from quality, to EHS and compliance while
            significantly reducing the time and effort involved in preparing for
            and conducting these audits.
            <br />
          </p>
          <a href="https://auditmaster.synergisticsol.org">
            {/* <h4 className="uppercase font-head mt-12 xl:text-xl text-center lg:text-left">
              download app now
            </h4> */}
            <Button props="Download the app now" />
          </a>
          <div className="flex flex-col md:flex-row justify-center lg:justify-start items-center">
            <img
              src={GooglePlay}
              alt=""
              className="w-[50%] max-w-[200px] min-w-[100px] p-4"
            />
            <img
              src={AppStore}
              alt=""
              className="w-[50%] max-w-[200px] min-w-[100px] p-4"
            />
            <img
              src={windowsStore}
              alt=""
              className="w-[50%] max-w-[200px] min-w-[100px] p-4"
            />
          </div>
        </div>
        <div className="flex basis-[10%]"></div>
        <div className="flex basis-[50%] justify-center" data-aos="fade-left">
          <img src={phone} alt="" />
        </div>
      </div>
    </div>
  )
}

export default AppFrontSection
