import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import '../App.css'
import Button from '../Components/button'
// import TableData from '../Data/Data'

const HoverTableComponent = () => {
  const myRef = useRef(null)
  const [tableItem, setTableItem] = useState(-1)
  const changeClass = () =>{
    let str = "my-6 lg:w-full w-[80vw] basis-[40%]  m-auto parent flex-col flex "
   if (tableItem !== -1){
    str += `table${tableItem}`
   } 
    return str
  }
  return (
    <div className=" flex flex-col p-4 lg:flex-row md:mt-8 md:text-lg font-body font-medium text-lite my-16">
      <div className="basis-[60%] flex">
        <div className="flex items-start  md:w-auto px-4 justify-center flex-col text-lite">
          <h4 className="">ISO 9001</h4>
          <h2 className="font-semibold font-subhead leading-10 xl:text-5xl">
            <span className="font-extrabold ">ISO</span> Services
          </h2>
          <br />
          <p className="xl:text-xl">
            Synergistic Solutions is an ISO 9001, ISO 14001, and ISO 45001
            consulting, and auditing business that specializes in assisting
            firms of all sizes in developing world-class quality management
            systems that offer high-level strategic value while being fully
            compliant with all international standards.
          </p>
          <br />
          <p className="xl:text-xl">
            Our skilled team of quality management consultants is standing by to
            assist you every step in and help you take every step toward
            certification with total confidence.
          </p>
          <br />
          <p className="xl:text-xl">
            At Synergistic Solutions we are never satisfied with you simply
            passing an audit. We believe that having a piece of paper comes
            second to implementing a cutting-edge quality system that helps your
            company from the ground up. We're professionally qualified
            consultants with over 25 years of auditing and quality management
            experience, and we won't stop until we've created a totally
            personalized and cutting-edge solution that elevates your quality
            and delivery to new heights while reducing cost and error to zero.
          </p>
          <br />
          <Link to="/services">
          {/* <button className="style-1 w-max xl:px-12 px-8 inline-flex h-12 align-middle xl:min-w-[12rem] xl:min-h-48 xl:text-xl">
            Learn More
          </button> */}
          <Button props="Learn More"/>
          </Link>
        </div>
      </div>
      <div className={changeClass()}>
      <Link to="/services">
        <div className="flex flex-row justify-center ">
          <div ref={myRef}
          onMouseEnter={() => {setTableItem(1)}} 
          onMouseLeave={() => {setTableItem(-1)}} 
          className="md:basis-2/4 basis-56 bg-black bg-opacity-50 font-semibold h-44 p-4 border-[1px] hover:bg-two cursor-pointer transition-all duration-150">
            ISO 9001:2015
            <p className={tableItem === 1 ? "hidden md:flex" : "hidden" + " mt-4  text-xs"}>
              The ISO 9001:2015 standard specifies the requirements for a quality management system.
            </p>
          </div> 
          <div
          onMouseEnter={() => {setTableItem(2)}} 
          onMouseLeave={() => {setTableItem(-1)}} 
          className="md:basis-2/4 basis-56 bg-black bg-opacity-50 font-semibold h-44 p-4 border-[1px] hover:bg-two cursor-pointer transition-all duration-150">
            ISO 14001:2015
            <p className={tableItem === 2 ? "hidden md:flex" : "hidden" + " mt-4  text-xs"}>
              ISO 14001:2004 is an internationally recognized quality management system.
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-center">
          <div 
          onMouseEnter={() => {setTableItem(3)}} 
          onMouseLeave={() => {setTableItem(-1)}} 
          className="md:basis-2/4 basis-56 bg-black bg-opacity-50 font-semibold h-44 p-4 border-[1px] hover:bg-two cursor-pointer transition-all duration-150">
            OHSAS 18001
            <p className={tableItem === 3 ? "hidden md:flex" : "hidden" + " mt-4  text-xs"}>
              OHSAS 18001 places a proactive and preventative emphasis on risk-control factors.
            </p>
          </div>
          <div 
          onMouseEnter={() => {setTableItem(4)}} 
          onMouseLeave={() => {setTableItem(-1)}} 
          className="md:basis-2/4 basis-56 bg-black bg-opacity-50 font-semibold h-44 p-4 border-[1px] hover:bg-two cursor-pointer transition-all duration-150">
            ISO 45001:2018
            <p className={tableItem === 4 ? "hidden md:flex" : "hidden" + " mt-4  text-xs"}>
              ISO 45001 is a global standard for Occupational Health and Safety Management Systems.
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-center">
          <div 
          onMouseEnter={() => {setTableItem(5)}} 
          onMouseLeave={() => {setTableItem(-1)}} 
          className="md:basis-2/4 basis-56 bg-black bg-opacity-50 font-semibold h-44 p-4 border-[1px] hover:bg-two cursor-pointer transition-all duration-150">
            Outsourced Internal Auditing
            <p className={tableItem === 5 ? "hidden md:flex" : "hidden" + " mt-4  text-xs"}>
              Outsourcing internal audits is the answer that allISO-certified companies should use.
            </p>
          </div>
          <div 
          onMouseEnter={() => {setTableItem(6)}} 
          onMouseLeave={() => {setTableItem(-1)}} 
          className="md:basis-2/4 basis-56 bg-black bg-opacity-50 font-semibold h-44 p-4 border-[1px] hover:bg-two cursor-pointer transition-all duration-150">
            R2:2013
            <p className={tableItem === 6 ? "hidden md:flex" : "hidden" + " mt-4  text-xs"}>
              The R2:2013 standard requires Interconnection to adhere to the highest industry standards.
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-center">
          <div 
          onMouseEnter={() => {setTableItem(7)}} 
          onMouseLeave={() => {setTableItem(-1)}} 
          className="md:basis-2/4 basis-56 bg-black bg-opacity-50 font-semibold h-44 p-4 border-[1px] hover:bg-two cursor-pointer transition-all duration-150">
            Supplier Audits
            <p className={tableItem === 7 ? "hidden md:flex" : "hidden" + " mt-4  text-xs"}>
              Supplier audits can be conducted to a number of international standards.
            </p>
          </div>
          <div
          onMouseEnter={() => {setTableItem(8)}} 
          onMouseLeave={() => {setTableItem(-1)}}  
          className="md:basis-2/4 basis-56 bg-black bg-opacity-50 font-semibold h-44 p-4 border-[1px] hover:bg-two cursor-pointer transition-all duration-150">
            Second Party Audits
            <p className={tableItem === 8 ? "hidden md:flex" : "hidden" + "  mt-4  text-xs"}>
              Second party or supplier auditing systems that are well planned and performed.
            </p>
          </div>
        </div>
        {/* {console.log(changeClass())} */}
        {/* {TableData.map((item, index)=>{
          return(
          <div onMouseEnter={()=>{setTableItem(index)}} onMouseLeave={()=>{setTableItem(-1)}}  key={index} className="flex flex-col justify-start items-start">
              <h4>{item.service}</h4>
              <p className={`${tableItem === index ? "": "hidden"}`}>{item.content}</p>
            </div>
          
            )})} */}
            </Link>
      </div>
      
    </div>
  )
}

export default HoverTableComponent
