import React from 'react'
import FrontComponent from './1-FrontComponent'
import '../App.css'
import HustleComponent from './2-HustleComponent'
import HoverTableComponent from './3-HoverTableComponent'
import AboutUsComponent from './4-AboutUsComponent'
import Contact from './5-ContactComponent'
import Footer from './6-Footer'
import App from '../Sections-AppPage/A1-FrontSection'
import Gif from './Gif'

const ComponentCaller = () => {
  return (
    // <div className="flex sm:min-w-full">
    <div className='overflow-x-hidden'>
      {/* <div className="bg-one bg-opacity-80 w-screen h-screen -z-50"> */}
      <FrontComponent />
      <Gif/>
      <App/>
      <AboutUsComponent />
      <HoverTableComponent />
      <HustleComponent />
      <Contact />
      <Footer />
    </div>
    // </div>
  )
}

export default ComponentCaller
