import React from 'react'
import Timer from 'react-countdown'

const Countdown = () => {
    const Completionist = () => <span>Get the app now!</span>

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    let months = Math.round(days / 30, 0)
    days = days % 30

    if (completed) {
      // Render a complete state
      return <Completionist />
    } else {
      // Render a countdown
      return (
        <>
          <span>
            In {months} months, {days} day(s), {hours} hours, {minutes} minutes
            and {seconds} seconds
          </span>
        </>
      )
    }
  }
  return (
    <div className="animate-bounce text-center font-subhead text-3xl sm:text-3xl md:text-3xl lg:text-3xl mr-2 text-yellow-500 leading-tight mt-6 md:mt-4">
        <Timer date={1663276935000} renderer={renderer} />
      </div>
  )
}

export default Countdown