import React, { useState } from 'react'

import pic1 from '../assets/images/iPhoneX-Mockup 1.png'
import pic2 from '../assets/images/001.png'
import pic3 from '../assets/images/003.png'
import pic4 from '../assets/images/004.png'
import pic5 from '../assets/images/000.png'

// import Fade from 'react-reveal/Fade'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, EffectCoverflow } from 'swiper'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/effect-cards'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const Carousel = () => {
  const [setSwiperRef] = useState(null)

  return (
    <div className="flex flex-col md:flex-col mt-8 text-center ">
      <div className="flex basis-[10%]"></div>
      <div className="text-white flex flex-col justify-center basis-[30%]">
        <h2 className="uppercase font-subhead xl:text-5xl mb-4">
          checkout our app interface look
        </h2>
        <p className="px-4 xl:text-xl">
        Our app offers modular, subscription-based quality management interface that's secure, cloud-based and affordable. It includes a document control module, as well as complaints management, audit management, and training modules.
        </p>
      </div>
      <div className="flex basis-[10%]"></div>
      {/* Medium screen carousel */}
      {/* <Fade> */}
        <div className="basis-[40%] hidden md:flex mt-8" data-aos="fade">
          <Swiper
            onSwiper={setSwiperRef}
            effect={'coverflow'}
            grabCursor={false}
            centeredSlides={true}
            loop={true}
            slidesPerView={2}
            coverflowEffect={{
              rotate: 0,
              stretch: 100,
              depth: 150,
              modifier: 1.5,
              slideShadows: false
            }}
            pagination={true}
            navigation={true}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={pic1} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={pic2} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={pic3} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={pic4} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={pic5} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
      {/* </Fade> */}
      {/* Smaller screen carousel */}
      {/* <Fade> */}
        <div className="flex basis-[40%] md:hidden" data-aos="fade">
          <Swiper
            onSwiper={setSwiperRef}
            slidesPerView={1}
            centeredSlides={true}
            loop={true}
            spaceBetween={30}
            pagination={{
              clickable: true
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={pic1} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={pic2} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={pic3} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={pic4} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={pic5} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
      {/* </Fade> */}
      <div className="flex basis-[10%]"></div>
    </div>
  )
}

export default Carousel
