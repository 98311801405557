import React from 'react'

const Button = ({props}) => {
  return (
        <button className="mt-2 style-1 w-max xl:px-12 px-8 inline-flex h-12 align-middle xl:min-w-[12rem] xl:min-h-48 xl:text-xl">
            {props}
        </button>
  )
}

export default Button