import React from 'react'
import AppFrontSection from '../Sections-AppPage/A1-FrontSection'
import AboutOurApp from '../Sections-AppPage/A2-aboutOurApp'
import Features from '../Sections-AppPage/A3-Features'
import Carousel from '../Sections-AppPage/A4-Carousel'
import DownloadApp from '../Sections-AppPage/A5-DownloadApp'
import Contact from '../Sections-AppPage/A6-Contact'
import Footer from '../Sections/6-Footer'
import Countdown from '../Sections-AppPage/countdown'


const AppPage = () => {
  return (
    <div className="flex flex-col font-body overflow-x-hidden">
      <Countdown/>
      <AppFrontSection />
      <AboutOurApp />
      <Features />
      <Carousel />
      <DownloadApp />
      <Contact />
      <Footer />
    </div>
  )
}

export default AppPage
