import React from 'react'
import { Link } from 'react-router-dom'
import { NavNames } from '../Data/Data'
import '../App.css'

const Footer = () => {
  let nameF = NavNames.slice(0, 2)
  let nameL = NavNames.slice(2, 4)
  return (
    <div className="flex flex-col">
      <div className="md:w-auto px-4 fillspace py-6 md:py-12 flex flex-col-reverse md:flex-row justify-between font-body text-lite bg-transparent">
        <div className='m-auto p-4 md:m-0 md:p-0 flex flex-col gap-y-2'><p>@Synergistic Solutions 2022</p><p>Created With 🧡 by <a href="https://koretechx.com/" target="_blank" className="hover:text-[#fc9c06] transition-all">Koretechx</a></p></div>
        <div></div>
        <div className="hidden md:flex md:flex-row transition-all ease-in duration-150 items-center">
          {NavNames.map((item, index) => (
            <Link to={item.link}>
              <div
                key={index}
                className="font-bold pt-2 md:pt-0 cursor-pointer hover:brightness-75 uppercase mx-8 transition-all"
              >
                {item.name}
              </div>
            </Link>
          ))}
        </div>

        <div className="flex text-left flex-col md:hidden">
          <div className=" flex flex-row mx-12 md:hidden items-center justify-between transition-all ease-in duration-150 py-2">
            {nameF.map((item, index) => (
              <Link to={item.link} >
                <div
                  key={index}
                  className="font-bold  pt-2 md:pt-0 cursor-pointer hover:brightness-75 uppercase transition-all"
                >
                  {item.name}
                </div>
              </Link>
            ))}
          </div>
          <div className=" flex flex-row mx-12 md:hidden items-center justify-between transition-all ease-in duration-150 py-2">
            {nameL.map((item, index) => (
              <Link to={item.link}>
                <div
                  key={index}
                  className="font-bold  pt-2 md:pt-0 cursor-pointer hover:brightness-75 uppercase transition-all"
                >
                  {item.name}
                </div>
              </Link>
            ))}
          </div>
        </div>
        
      </div>
      {/* <div class="px-4 text-lite text-md font-body text-center pb-2">
          Created With 🧡 by <a href="https://koretechx.com/" target="_blank" className="hover:text-[#fc9c06] transition-all">Koretechx</a>
      </div> */}
    </div>
  )
}

export default Footer
