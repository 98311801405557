import React, { useState } from 'react'
import background from '../assets/videos/trucks.mp4'
import preview from '../assets/images/iso-9001.jpg'
import Modal from 'react-modal/lib/components/Modal'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'

const QualityManagement = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  function openModal() {
    setModalIsOpen(true)
  }
  function closeModal() {
    setModalIsOpen(false)
  }
  const modalStyles = {
    content: {
      margin: '2rem'
    }
  }
  return (
    <div className="font-body mt-8 mb-16 md:w-auto m-4" data-aos="fade">
      <div className="flex flex-col ">
        <div className="flex flex-col md:flex-row h-full ">
          <div className="w-full md:w-full flex items-start px-4  justify-center flex-col text-lite">
            {/* <h4 className="text-lg xl:text-xl">ISO 9001</h4> */}
            <h2 className="font-subhead xl:text-5xl text-center md:text-left">
              Quality Management Standard
            </h2>
            <br />
            <p className="xl:text-xl">
              ISO 9001 is the most sought-after and internationally acclaimed
              management system standard.
              <br />
              Internally, the ISO 9001 Quality Management Standard helps to
              improve operational and management efficiency and effectiveness.
            </p>
            <br />
            <p className="xl:text-xl">
              ISO 9001 is not only a Quality Assurance Standard or a degree of
              excellence; it is a way to ensure that customer requirements are
              met with consistency.
            </p>
            <br />
            <Link to="/services">
            <p className="cursor-pointer">
              Read more
              <svg
                className="w-[12px] inline-flex mx-2 fill-lite animate-bounce"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path d="M374.6 310.6l-160 160C208.4 476.9 200.2 480 192 480s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 370.8V64c0-17.69 14.33-31.1 31.1-31.1S224 46.31 224 64v306.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0S387.1 298.1 374.6 310.6z" />
              </svg>
            </p>
            </Link>
            <br />
          </div>
          <div className="mr-4 w-full md:w-3/2 flex justify-center items-center">
            <div className=" w-4/5 h-5/5 drop-shadow-2xl">
              {/* <video
                autoPlay
                loop
                muted
                className="object-cover w-3/5 h-4/5 min-w-full min-h-full max-w-none "
              >
                <source src={background} type="video/mp4" />
                Your browser does not support the video tag.
              </video> */}
              <img
                src={preview}
                alt="Iso9001"
                className="object-cover w-3/5 h-4/5 min-w-full min-h-full animate- max-w-none "
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                onClick={() => openModal()}
                className="absolute w-16  fill-mid top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] cursor-pointer hover:brightness-75 hover:scale-150 hover:animate-spin"
              >
                <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM176 168V344C176 352.7 180.7 360.7 188.3 364.9C195.8 369.2 205.1 369 212.5 364.5L356.5 276.5C363.6 272.1 368 264.4 368 256C368 247.6 363.6 239.9 356.5 235.5L212.5 147.5C205.1 142.1 195.8 142.8 188.3 147.1C180.7 151.3 176 159.3 176 168V168z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        className="Modal"
        overlayClassName="Overlay"
      >
        <button
          className="absolute z-50 -right-8 md:top-4 md:right-0"
          onClick={() => closeModal()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            className="w-4 fill-red-500 flex justify-end items-end"
          >
            <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
          </svg>
        </button>
        <ReactPlayer
          url={background}
          width="100%"
          height="100%"
          controls={true}
          playing={true}
        />
      </Modal>
    </div>
  )
}

export default QualityManagement
