import React from 'react'
import pic from '../assets/images/app2.png'
// import Slide from 'react-reveal/Slide'
// import Fade from 'react-reveal/Fade'

const AboutOurApp = () => {
  return (
    <div className="flex flex-col lg:flex-row mt-8 p-4">
      <div className="flex basis-[10%]"></div>
      {/* <Slide left> */}
        <div className=" flex flex-col basis-[30%] justify-center" data-aos="fade-right">
          <h2 className="uppercase font-subhead text-white flex lg:hidden text-center justify-center mb-4">
            About our app
          </h2>
          <img src={pic} alt="" />
        </div>
      {/* </Slide> */}
      <div className="flex basis-[5%]"></div>
      <div className="flex basis-[5%]"></div>
      {/* <Fade> */}
        <div className="flex basis-[50%] justify-center flex-col items-center gap-y-6" data-aos="fade">
          <h2 className="uppercase font-subhead text-white hidden lg:flex">
            About our app
          </h2>
          <p className="text-white">
          Quality management is inextricably linked with ISO quality standards and guidelines mandated by the FDA and other similar regulatory agencies. Most companies find themselves relying on our proven and robust quality management app to keep pace with these regulatory guidelines. Our well-designed QMS app that has been implemented in a variety of settings not only enhances the efficiency of quality systems but also ensures that maximum performance is in harmony with cost effectiveness.
          </p>
          <div className="flex bg-white bg-opacity-20 rounded-xl p-3 mt-4">
            <div className="flex flex-col items-center basis-[10%] p-1 align-center justify-center">
              <svg
                className="w-6 fill-two"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" />
              </svg>
            </div>
            <div className="basis-[90%] ">
              <h4 className="uppercase text-white font-bold">
                Creative design
              </h4>
              <p className="text-white text-sm">
              Our app is designed with the worker in mind. It's easy to adopt, intuitive to use, and promotes sustainable change.Our reliable and flexible quality management app is the key to success
              </p>
            </div>
          </div>
          <div className="flex bg-white bg-opacity-20 rounded-xl p-3 mt-4">
            <div className="flex flex-col items-center basis-[10%] p-1 align-center justify-center">
              <svg
                className="w-6 fill-two"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" />
              </svg>
            </div>
            <div className="basis-[90%] ">
              <h4 className="uppercase text-white font-bold">Easy to Use</h4>
              <p className="text-white text-sm">
              One of the obstacles with adopting a new system is the learning curve. That's not a problem with our app. The moment you open the app you'll feel like you've been using it for years.
              </p>
            </div>
          </div>
          <div className="flex bg-white bg-opacity-20 rounded-xl p-3 mt-4">
            <div className="flex flex-col items-center basis-[10%] p-1 align-center justify-center">
              <svg
                className="w-6 fill-two"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" />
              </svg>
            </div>
            <div className="basis-[90%] ">
              <h4 className="uppercase text-white font-bold">
                best user experience
              </h4>
              <p className="text-white text-sm">
              This software is made for the workers. It has a high adoption rate due to the ease of functionality and UIUX. It also offers transparency in safety across the organization. It ensures that all levels of the company can hold each other accountable.
              </p>
            </div>
          </div>
        </div>
      {/* </Fade> */}
      <div className="flex basis-[5%]"></div>
    </div>
  )
}

export default AboutOurApp
