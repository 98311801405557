import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/22.png'
import '../App.css'

const TopNavbar = () => {
  return (
    <nav className="z-50 md:mr-4 ml-12 fillspace md:ml-20 px-2 mt-2 py-6 font-body">
      <div className="container flex justify-between m-auto">
        <div className="md:w-1/2">
          <Link to="/">
            {/* <h3 className="font-semibold  font-subhead text-lite whitespace-nowrap cursor-pointer pl-4">
              Synergistic Solutions
            </h3> */}
            <img src={logo} alt="" className="lg:w-1/3  cursor-pointer pl-4"/>
          </Link>
        </div>

        <div className="h-full sm:hidden md:flex">
          <ul className=" bg-black hidden md:flex md:bg-transparent ">
            <li className="inline-flex hover:animate-pulse hover:scale-110 transition-all ease-in-out mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill="var(--lite)"
                className="w-4"
              >
                <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
              </svg>

              <h4 className="text-lite mx-2 cursor-pointer md:text-base lg:text-xl hover:brightness-90">
                <a href="tel:+12148403136">1&nbsp;-214-&nbsp;840-3136</a>
              </h4>
            </li>
            <li className="inline-flex hover:transform hover:animate-pulse hover:scale-110 transition-all ease-in-out mx-2">
              <Link to="/contactus">
                <h4 className="text-lite mx-2 md:text-base lg:text-xl cursor-pointer hover:brightness-90">
                  Contact Us
                </h4>
              </Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="w-4  hover:brightness-90 "
                fill="var(--lite)"
              >
                <path d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z" />
              </svg>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default TopNavbar
