import React, { useState } from 'react'
import background from '../assets/images/meeting.png'
import '../App.css'
import Modal from 'react-modal/lib/components/Modal'
import ReactPlayer from 'react-player'
import trucks from '../assets/videos/trucks.mp4'
// import Fade from 'react-reveal/Fade'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const AboutUsFrontSection = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  function openModal() {
    setModalIsOpen(true)
  }
  function closeModal() {
    setModalIsOpen(false)
  }
  const modalStyles = {
    content: {
      margin: '2rem'
    }
  }
  return (
    <div className="font-body md:w-auto">
      <div className="flex flex-col md:flex-row h-full ">
        {/* <Fade> */}
          <div className="md:pt-18 md:w-[65%] lg:pl-24 text-center md:text-left flex items-start px-4 justify-center flex-col text-lite pb-6 h-auto lg:h-[80vh]" data-aos="fade-right">
            <div className="w-full">
              <h2 className="font-head text-5xl sm:text-6xl md:text-7xl lg:text-8xl mr-2 text-lite leading-tight">
                About Synergistic
              </h2>
            </div>

            <p className="text-left">
              Synergistic Solutions is a Texas based auditing and consulting
              firm that has been assisting businesses in maintaining their ISO
              Certifications for 25 years.
            </p>
            <br />
            <div className="w-full flex justify-center md:justify-start">
              {/* <button className="style-1 mt-4 ss:mt-6 md:mx-4 rounded-tr-2xl flex  justify-center w-72 md:w-36 lg:w-48 lg:h-16 h-12"> */}
              <button className="style-1 mt-4 ss:mt-6 md:mx-4 rounded-tr-2xl flex  text-base justify-center w-72 md:w-36 lg:w-48 lg:h-16 h-12">
                Learn More
              </button>
            </div>
          </div>
        {/* </Fade> */}

        <div className="  flex justify-center items-center" data-aos="fade-left">
          <div className="w-4/5 h-4/5 drop-shadow-2xl relative">
            <LazyLoadImage
              src={background}
              alt=""
              className="object-cover w-3/5 h-4/5 min-w-full min-h-full max-w-none brightness-50"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              onClick={() => openModal()}
              className="absolute w-12 fill-mid top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] cursor-pointer hover:brightness-75 hover:scale-150 hover:animate-spin"
            >
              <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM176 168V344C176 352.7 180.7 360.7 188.3 364.9C195.8 369.2 205.1 369 212.5 364.5L356.5 276.5C363.6 272.1 368 264.4 368 256C368 247.6 363.6 239.9 356.5 235.5L212.5 147.5C205.1 142.1 195.8 142.8 188.3 147.1C180.7 151.3 176 159.3 176 168V168z" />
            </svg>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        className="Modal"
        overlayClassName="Overlay"
      >
        <button
          className="absolute z-50 -right-8 md:top-4 md:right-0"
          onClick={() => closeModal()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            className="w-4 fill-red-500 flex justify-end items-end"
          >
            <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
          </svg>
        </button>
        <ReactPlayer
          url={trucks}
          width="100%"
          height="100%"
          controls={true}
          playing={true}
        />
      </Modal>
    </div>
  )
}

export default AboutUsFrontSection
