import React, { useState } from 'react'
import Axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'

const Ask = () => {
  const [newEmail, setNewEmail] = useState({
    name:"john doe",
    email: "john@example.com",
    phone: 123,
    country: "US",
    company: "Synergistic Solutions",
    service: "ISO",
    message:"hello world"
  })
  const handleChange = (e) =>{
    setNewEmail({...newEmail, [e.target.name]: e.target.value})
  }

const notify = () =>
  toast.success('📧 Email Sent!', {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  })

  const handleSubmit = async (e) => {
    const email ={
      "name":newEmail.name,
      "email": newEmail.email,
      "phone": newEmail.phone,
      "country": newEmail.country,
      "company": newEmail.company,
      "service": newEmail.service,
      "message": newEmail.message
    }
    console.log(email)
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }

    e.preventDefault()
    await Axios.post("https://synergisticsol.org/email",email).then(notify()).catch((err)=>{console.log(err)})
   }
  
  return (
    <div className="font-body mt-8 md:w-auto bg-black bg-opacity-75 py-20" >
      <div className="flex flex-col  justify-center">
        <div className="flex flex-col md:flex-row h-full ">
          <div className="w-full md:w-full flex items-start px-8 justify-center flex-col text-lite" data-aos="zoom">
            <h2 className="font-subhead text-center md:text-left">
              Need To ask Something?
            </h2>
            <h3 className="">
              Let our consultants show you how to get the most out of your QMS
              by just filling the form.
            </h3>
            <br />
            <p>Have a great day!</p>
          </div>
          <div className="mr-4 w-full  flex justify-center items-center" data-aos="zoom">
            <div className="md:ml-12 px-4 drop-shadow-2xl">
              <form>
                <div className="flex">
                  <input
                    className="w-full bg-transparent text-white text-sm mt-2 p-3 focus:outline-none border-b-2 focus: focus:border-b-two"
                    type="text"
                    placeholder="Your Name"
                    name="name"
                    required
                    onChange={(e)=>{handleChange(e)}}
                  />
                  <input
                    className="w-full bg-transparent text-white text-sm mt-2 p-3 focus:outline-none ml-4 border-b-2 focus: focus:border-b-two"
                    type="email"
                    placeholder="Your Email"
                    name="email"
                    required
                    onChange={(e)=>{handleChange(e)}}
                  />
                </div>
                <div className="flex">
                  <input
                    className="w-full bg-transparent text-white text-sm mt-2 p-3 focus:outline-none border-b-2 focus: focus:border-b-two"
                    type="number"
                    placeholder="Phone Number"
                    name="phone"
                    required
                    onChange={(e)=>{handleChange(e)}}
                  />
                  <input
                    className="w-full bg-transparent text-white text-sm mt-2 p-3 focus:outline-none ml-4 border-b-2 focus: focus:border-b-two"
                    type="text"
                    placeholder="Country"
                    name="country"
                    required
                    onChange={(e)=>{handleChange(e)}}
                  />
                </div>
                <div className="flex">
                  <input
                    className="w-full bg-transparent text-white text-sm mt-2 p-3 focus:outline-none border-b-2 focus: focus:border-b-two"
                    type="text"
                    placeholder="Company Name"
                    name="company"
                    required
                    onChange={(e)=>{handleChange(e)}}
                  />
                  <select
                    name="service"
                    id=""
                    required
                    placeholder="Interested In"
                    className="w-full bg-transparent text-lite mt-2 p-3 focus:outline-none ml-4 border-b-2 focus: focus:border-b-two bg-black bg-opacity-75 "
                    onChange={(e)=>{handleChange(e)}}
                  >
                    <option
                      className="bg-[rgba(0,0,0,0.7)]"
                      value="Interested In"
                    >
                      Interested In
                    </option>
                    <option
                      className="bg-[rgba(0,0,0,0.7)]"
                      value="ISO 9001:2015"
                    >
                      ISO 9001:2015
                    </option>
                    <option
                      className="bg-[rgba(0,0,0,0.7)]"
                      value="ISO 14001:2015"
                    >
                      ISO 14001:2015
                    </option>
                    <option
                      className="bg-[rgba(0,0,0,0.7)]"
                      value="OHSAS 18001"
                    >
                      OHSAS 18001
                    </option>
                    <option
                      className="bg-[rgba(0,0,0,0.7)]"
                      value="ISO 45001:2018"
                    >
                      ISO 45001:2018
                    </option>
                    <option
                      className="bg-[rgba(0,0,0,0.7)]"
                      value="Outsourced Internal Auditing"
                    >
                      Outsourced Internal Auditing
                    </option>
                    <option className="bg-[rgba(0,0,0,0.7)]" value="R2:2013">
                      R2:2013
                    </option>
                    <option
                      className="bg-[rgba(0,0,0,0.7)]"
                      value="Supplier Audits"
                    >
                      Supplier Audits
                    </option>
                    <option
                      className="bg-[rgba(0,0,0,0.7)]"
                      value="Second Party Audits"
                    >
                      Second Party Audits
                    </option>
                  </select>
                </div>
                <div className="w-full">
                  <textarea
                    className="w-full h-32 bg-transparent text-white text-sm mt-2 p-3 focus:outline-none focus:border-bg-yellow border-b-2"
                    placeholder="Message"
                    name="message"
                    onChange={(e)=>{handleChange(e)}}
                  ></textarea>
                </div>
                <div className="flex justify-around">
                  <button
                    type="button"
                    className="focus:outline-none text-dark hover:text-lite bg-two hover:bg-one focus:ring-4 focus:ring-one font-medium rounded-lg text-sm px-5 py-2.5 mt-4 mr-2 mb-2 dark:focus:ring-two"
                    onClick={(e)=>{handleSubmit(e)}}
                  >
                    Submit
                  </button>
                  {/* <ToastContainer limit={3} /> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Ask
