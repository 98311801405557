import React from 'react'
import '../App.css'
import Lady from '../assets/images/lady.jpg'
// import Fade from 'react-reveal/Fade'
// import Slide from 'react-reveal/Slide'
// import TestComponent from './TestComponent'
// import TestComponent from './TestComponent_2'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ContactFront = () => {
  return (
    <>
      <div className="font-body font-extrabold lg:mt-36 px-4">
        <div className="h-full w-full flex flex-col-reverse lg:flex-row text-lite ">
          {/* <Slide left> */}
            <div className="flex flex-col self-center justify-center items-center mt-8 md:mt-0 md:pl-12 lg:pl-24 md:items-start sm:items-center lg:h-[80vh] md:pr-0  md:pr-8 lg:mb-12" data-aos="fade-right">
              <h1 className="font-head text-center md:text-left text-5xl sm:text-6xl md:text-7xl lg:text-8xl mr-2 text-lite leading-tight">
                GET AHOLD OF US
              </h1>
              <p className=" mr-2 text-lite leading-tight xl:text-xl text-center md:text-left">
                There are undoubtedly numerous reasons to acquire ISO
                certificates. However, if you want to make the most of your
                certified status, it's critical to evaluate the advantages of
                hiring an ISO 9001 implementation consultant. Synergistic
                Solutions is one such consulting service that will assist you in
                reaping the greatest benefits from a tailored approach.
              </p>

              <br />
              <div className="uppercase text-lite flex flex-col md:flex-row text-lg mt-8 w-max">
                <button className="style-1 mt-4 md:mx-4 rounded-tr-2xl inline-flex text-base justify-center align-middle sm:w-72 md:w-48 lg:h-16 h-12">
                  Contact Us
                </button>
              </div>
            </div>
          {/* </Slide> */}
          {/* <Fade> */}
          {/* absolute inset-0 -z-10 brightness-50 md:brightness-100 */}
          {/* sm:pr-6 */}
            <div className=" md:z-0 md:mr-4 lg:min-w-[45%] md:min-w-[50%] md:py-8 text-center md:relative grid justify-items-center items-center md:p-12" data-aos="fade-left">
              <LazyLoadImage src={Lady} alt="Lady" />
            </div>
          {/* </Fade> */}
        </div>
      </div>
    </>
  )
}

export default ContactFront
