import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import quality from '../assets/videos/QualityGif.gif'

const Gif = () => {
  return (
    <div className="mt-16 mb-16 justify-center align-middle flex" data-aos="zoom-in-up">
          <LazyLoadImage src={quality} alt="" draggable="false" />
        </div>
  )
}

export default Gif