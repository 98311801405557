import React from 'react'

const Map = () => {
  return (
    <div className=" h-[32rem] md:w-auto border-l-0 border-b-0 border-[0.5px] border-lite  md:pr-0">
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53642.48167854961!2d-96.9981444482525!3d32.82790224953038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e8309c5607d63%3A0xdcf16c3ef6f437c8!2sIrving%2C%20TX%2075061%2C%20USA!5e0!3m2!1sen!2s!4v1657918239623!5m2!1sen!2s"
        allowFullScreen=""
        className="w-full h-full"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  )
}

export default Map