import React, { useEffect, useState } from 'react'
import './App.css'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import ComponentCaller from './Sections/0-ComponentCaller'
import Contact from './Pages/Contact'
import AppPage from './Pages/AppPage'
import SideBar from './Pages/SideBar'
import TopNavbar from './Components/TopNavbar'
import background from './assets/videos/Synergisticoptimized-3.mp4'
import AboutUs from './Pages/AboutUs'
import Services from './Pages/ServicesPage'
import ScrollToTop from './Components/ScrollToTop'
import ReactLoading from 'react-loading'
import AOS from 'aos'
import 'aos/dist/aos.css'
import AuditMaster from './Pages/auditMaster'

// const ComponentCaller = lazy(() => import('./Sections/0-ComponentCaller'))
// const Contact = lazy(() => import('./Pages/Contact'))
// const AppPage = lazy(() => import('./Pages/AppPage'))
// const SideBar = lazy(() => import('./Pages/SideBar'))
// const TopNavbar = lazy(() => import('./Components/TopNavbar'))
// // const background = lazy(() =>
// //   import('./assets/videos/Synergisticoptimized-3.mp4')
// // )
// const AboutUs = lazy(() => import('./Pages/AboutUs'))
// const Services = lazy(() => import('./Pages/ServicesPage'))

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 500
    })
    AOS.refresh()
    console.log('Created With 🧡 by Koretechx LLC (https://koretechx.com)')
  }, [])

  const [isLoading, setIsLoading] = useState(true)
  const showSideBar = () => {
    if (!isLoading) {
      return (
        <>
          <SideBar />
          <TopNavbar />
          <div className="md:pl-20 pl-10 pt-20 overflow-x-hidden">
            <Routes>
              <Route path="/" index element={<ComponentCaller />} />
              <Route path="/contactus" element={<Contact />} />
              <Route path="/app" element={<AppPage />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/services" element={<Services />} />
            </Routes>
          </div>
        </>
      )
    } else {
      // console.log("loading")
      return (
        <div className="flex justify-center align-center items-center bg-[#112D4E] h-[100vh]">
          <ReactLoading
            type={'spin'}
            color={'#3F72AF'}
            height={330}
            width={160}
            className="flex"
          />
        </div>
      )
    }
  }
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <header className="relative flex justify-center overflow-hidden -z-50">
            <video
              autoPlay
              loop
              muted
              className="fixed object-cover w-auto min-w-full min-h-full max-w-none "
              // ref={ref => headerVideo}
              onLoadStart={() => {
                setIsLoading(true)
              }}
              onLoadedData={() => {
                setIsLoading(false)
              }}
            >
              <source src={background} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </header>
          {showSideBar()}
          {/* // <SideBar />
          // <TopNavbar />
          // <div className="md:pl-20 pl-10 pt-20">
          //   <Routes>
          //     <Route path="/" index element={<ComponentCaller />} />
          //     <Route path="/contactus" element={<Contact />} />
          //     <Route path="/app" element={<AppPage />} />
          //     <Route path="/aboutus" element={<AboutUs />} />
          //     <Route path="/services" element={<Services />} />
          //   </Routes>
          // </div> */}
        </ScrollToTop>
      </BrowserRouter>
    </>
  )
}

export default App
