import React from 'react'
// import Slide from 'react-reveal/Slide'

import Pic1 from '../assets/images/service1.png'
import Pic2 from '../assets/images/service2.png'
import Pic3 from '../assets/images/service3.png'

const OurServices = () => {
  return (
    <div className="font-body mt-8 mb-16 md:w-auto">
      <div className="flex flex-col px-4">
        <div className="flex flex-col md:flex-row h-full ">
          <div className="flex md:pt-24 flex-col align-center justify-center items-center md:items-start sm:items-center h-[70vh] md:pl-24 md:pr-0 pr-4 " data-aos="fade-right">
            <h1 className="font-head text-5xl sm:text-6xl md:text-7xl lg:text-8xl mr-2 text-lite leading-tight">
              Our Services
              <br />
            </h1>
            <p className="  text-xl md:text-lg text-left text-lite leading-tight xl:text-xl">
              Synergistic Solutions knows that the key to a successful Quality
              Management System is one that meets all of the requirements for
              ISO 9001, ISO 14001, ISO 45001, and OHSAD 18001 certification
              while also improving overall company operations.
            </p>
            <div className="uppercase text-lite flex flex-col md:flex-row text-lg mt-8 w-max">
              {/* <button className="style-1 mt-4 ss:mt-6 md:mx-4 rounded-tr-2xl  inline-flex text-base justify-center align-middle w-72 md:w-36 lg:w-48 lg:h-12 h-12"> */}
              <button className="style-1 mt-4 md:mx-4 rounded-tr-2xl  inline-flex text-base justify-center align-middle w-72 md:w-36 lg:w-48 lg:h-12 h-12">
                Learn more
              </button>
            </div>
          </div>
          <div className="mr-4 w-full md:w-full flex justify-center items-center md:min-w-[50%]" data-aos="fade-left">
            <div className="h-full md:mr-4 px-10 lg:max-w-[100%] md:mt-20 lg:min-w-[70%] md:py-8 align-center text-center relative grid justify-items-center items-center">
              <div className="wrap ">
                <div className="grid grid-rows-5 grid-cols-5">
                  {/* <Slide right> */}
                    <img
                      src={Pic1}
                      alt=""
                      className="row-start-1 row-end-4 col-start-2 col-end-6 z-10"
                    />
                  {/* </Slide> */}
                  {/* <Slide left> */}
                    <img
                      src={Pic3}
                      alt=""
                      className="row-start-2 z-10 row-end-5 col-start-1 col-end-5"
                    />
                  {/* </Slide> */}
                  {/* <Slide right> */}
                    <img
                      src={Pic2}
                      alt=""
                      className="row-start-3 row-end-5 col-start-2 col-end-6 z-20"
                    />
                  {/* </Slide> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurServices
