import React from 'react'
import CEOQuoteSection from '../Sections-AboutUsPage/ABU-4-CEOQuoteSection'
import AboutUsFrontSection from '../Sections-AboutUsPage/ABU1-FrontSection'
import WhoAreWeSection from '../Sections-AboutUsPage/ABU2-WhoAreWeSection'
// import CarouselSection from '../Sections-AboutUsPage/ABU3-CarouselSection'
import WhyImportantSection from '../Sections-AboutUsPage/ABU4-WhyImportantSection'
import Footer from '../Sections/6-Footer'

// const CEOQuoteSection = lazy(()=>import('../Sections-AboutUsPage/ABU-4-CEOQuoteSection'))
// const AboutUsFrontSection = lazy(()=>import('../Sections-AboutUsPage/ABU1-FrontSection'))
// const CEOQuoteSection = lazy(()=>import('../Sections-AboutUsPage/ABU2-WhoAreWeSection'))
// const CEOQuoteSection = lazy(()=>import())
// const CEOQuoteSection = lazy(()=>import())

const AboutUs = () => {
  return (
    <div className="flex ">
      <div>
        <AboutUsFrontSection />
        <WhoAreWeSection />
        {/* <CarouselSection /> */}
        <CEOQuoteSection />
        <WhyImportantSection />
        <Footer />
      </div>
    </div>
  )
}

export default AboutUs
