import React from 'react'
import ServicesFront from '../Sections-ContactUs/1-ContactUsFront'
import ServicesQualityManagement from '../Sections-ContactUs/2-ContactQualityManagement'
import Discover from '../Sections-ContactUs/3-Discover'
import Ask from '../Sections-ContactUs/4-Ask'
import ContactSection from '../Sections-ContactUs/5-Contact'
import Map from '../Sections-ContactUs/6-map'
import Footer from '../Sections/6-Footer'

const Contact = () => {
  return (
    <div className="">
      <ServicesFront />
      <ServicesQualityManagement />
      <Discover />
      <Ask />
      <Map />
      <ContactSection />
      <Footer />
    </div>
  )
}

export default Contact
