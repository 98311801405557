import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../App.css'
import Soon from '../assets/images/coming-soon.png'

const SideBar = () => {
  const [bars, setBars] = useState(true)
  const handleClick = () => {
    setBars(!bars)
    // navMenu.classList.toggle('active')
  }

  return (
    <div
      className={
        bars
          ? 'w-10 md:w-20 fixed font-body font-bold z-50 flex flex-col h-full justify-between text-center bg-one bg-opacity-40 shadow-md border-mid border-r-[1px] transition-all ease-in-out duration-700'
          : 'w-full fixed font-body font-bold z-50 flex flex-col h-full justify-between text-center bg-one bg-opacity-90 shadow-md  border-mid border-r-[1px] transition-all ease-in-out duration-700'
      }
    >
      <div
        className={
          'hover:cursor-pointer hover:bg-two hover:bg-opacity-50 text-lite py-8 '
        }
        // onClick={() => setBars(!bars)}
        onClick={() => handleClick()}
      >
        {/* {bars && (
          <svg
            fill="var(--lite)"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            className="inline text-lite transition-all ease-linear duration-700 animate-pulse w-[45px] md:w-[30px]"
          >
            <path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z" />
          </svg>
        )}

        {!bars && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-20 md:w-8 flex items-start ml-6 text-lite transition-all ease-out duration-500 transform scale-150 fill-lite hover:transform hover:translate-x-2  bg-two bg-opacity-50 md:bg-transparent "
          >
            <path d="M9.375 233.4l128-128c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H480c17.69 0 32 14.31 32 32s-14.31 32-32 32H109.3l73.38 73.38c12.5 12.5 12.5 32.75 0 45.25c-12.49 12.49-32.74 12.51-45.25 0l-128-128C-3.125 266.1-3.125 245.9 9.375 233.4z" />
          </svg>
        )} */}
        <div
          id="navMenu"
          className={bars ? 'ml-[6px] md:ml-8 ' : 'ml-8 active'}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="">
        <ul
          className={
            bars
              ? 'text-lite font-subhead text-left cursor-pointer transition-all ease-out -ml-[150rem] hidden'
              : 'text-lite font-subhead cursor-pointer transition-all ease-out text-center text-7xl block '
          }
        >
          <Link to="/">
            <h4
              onClick={() => handleClick()}
              className="pl-4 my-2 hover:brightness-75 md:text-4xl text-5xl hover:transform hover:translate-x-2 transition-all ease-in-out duration-700"
            >
              Home
            </h4>
          </Link>
          <Link to="/app">
            <div className="flex justify-center m-auto w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 items-start relative" onClick={() => handleClick()}>
              <img
                src={Soon}
                alt=""
                className=" w-[200px] h-[140px] ml-4"
              />
              {/* <h4
                
                className="pl-4 my-2 hover:brightness-75 md:text-4xl text-5xl hover:transform hover:translate-x-2 transition-all ease-in-out duration-700 text-center"
              >
                <span className="font-bold">Audit</span> Master
              </h4> */}
            </div>
          </Link>
          <Link to="/services">
            <h4
              onClick={() => handleClick()}
              className="pl-4 my-2 hover:brightness-75 md:text-4xl text-5xl hover:transform hover:translate-x-2 transition-all ease-in-out duration-700"
            >
              Services
            </h4>
          </Link>
          <Link to="/aboutus">
            <h4
              onClick={() => handleClick()}
              className="pl-4 my-2 hover:brightness-75 md:text-4xl text-5xl hover:transform hover:translate-x-2 transition-all ease-in-out duration-700"
            >
              About Us
            </h4>
          </Link>
          <Link to="/contactus">
            <h4
              onClick={() => handleClick()}
              className="pl-4 my-2 hover:brightness-75 md:text-4xl text-5xl hover:transform hover:translate-x-2 transition-all ease-in-out duration-700"
            >
              Contact Us
            </h4>
          </Link>
        </ul>
      </div>

      <div className="flex flex-col items-center justify-center">
        {/* 
        //  */}
        {/*  */}
        {/* Contact Line in full screen sidebar */}
        {/*  */}
        {/*  */}
        {/*  */}
        <div className={bars ? 'hidden' : 'block'}>
          <ul className=" md:flex md:bg-transparent inline-flex">
            <a href="tel:+12148403136">
            <li className="inline-flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width={'20px'}
                fill="var(--lite)"
              >
                <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
              </svg>
              <h4 className="text-2xl text-lite mx-4 cursor-pointer hover:brightness-90">
                1&nbsp;-214-&nbsp;840-3136
              </h4>
            </li>
            </a>
          </ul>
        </div>
        <div
          className={
            bars
              ? 'transition-all duration-700'
              : 'transition-all duration-700 flex flex-row justify-center'
          }
        >
          <div
            className={
              bars
                ? 'p-4 py-4  hover:bg-opacity-20 transition-all ease-in text-lite duration-700 cursor-pointer border-mid border-x-0 fill-mid hover:fill-lite'
                : 'p-10 py-8  hover:bg-opacity-20 transition-all ease-in text-lite duration-700 cursor-pointer border-mid border-x-0 fill-mid hover:fill-lite flex flex-row'
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className={
                bars
                  ? 'w-6 inline text-lite transition-all ease-linear duration-700'
                  : 'w-8 inline text-lite transition-all ease-linear duration-700'
              }
            >
              <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
            </svg>
          </div>
          <div
            className={
              bars
                ? 'p-4 py-4  hover:bg-opacity-20 transition-all ease-in text-lite duration-700 cursor-pointer border-mid border-x-0 fill-mid hover:fill-lite'
                : 'p-10 py-8  hover:bg-opacity-20 transition-all ease-in text-lite duration-700 cursor-pointer border-mid border-x-0 fill-mid hover:fill-lite'
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className={
                bars
                  ? 'w-6 inline text-lite transition-all ease-linear duration-700'
                  : 'w-8 inline text-lite transition-all ease-linear duration-700'
              }
            >
              <path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z" />
            </svg>
          </div>
          <div
            className={
              bars
                ? 'p-4 py-4  hover:bg-opacity-20 transition-all ease-in text-lite duration-700 cursor-pointer border-mid border-x-0 fill-mid hover:fill-lite'
                : 'p-10 py-8  hover:bg-opacity-20 transition-all ease-in text-lite duration-700 cursor-pointer border-mid border-x-0 fill-mid hover:fill-lite'
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className={
                bars
                  ? 'w-6 inline text-lite transition-all ease-linear duration-700'
                  : 'w-8 inline text-lite transition-all ease-linear duration-700'
              }
            >
              <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBar
