import React from 'react'
import phone from '../assets/images/app4.png'
import GooglePlay from '../assets/images/googleplay.png'
import AppStore from '../assets/images/appstore.png'
import windowsStore from '../assets/images/windows.png'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Slide from 'react-reveal/Slide'

const DownloadApp = () => {
  return (
    <div className="flex flex-col lg:flex-row text-white p-4 sm:mt-8 md:mt-16">
      <div className="flex basis-[10%]"></div>
      {/* <Slide left> */}
        <div className=" flex flex-col basis-[30%] justify-center gap-y-6" data-aos="slide-right">
          <h2 className="uppercase font-subhead xl:text-5xl text-center md:text-left">
            download app now
          </h2>
          <p className="xl:text-xl mt-4">
          To implement a foolproof quality management process, you need a quality management service. The app automates all the steps, saving you time and ensuring that your products adhere to the quality standards.
          </p>
          <div className="flex flex-col md:flex-row justify-center lg:justify-start items-center mt-4">
            <img
              src={GooglePlay}
              alt=""
              className="w-[50%] max-w-[200px] min-w-[100px] p-4"
            />
            <img
              src={AppStore}
              alt=""
              className="w-[50%] max-w-[200px] min-w-[100px] p-4"
            />
            <img
              src={windowsStore}
              alt=""
              className="w-[50%] max-w-[200px] min-w-[100px] p-4"
            />
          </div>
          <div className="flex flex-col lg:flex-row text-sm align-center mt-4 mb-8 lg:mb-0">
            <div className="flex flex-col bg-two text-white m-2 rounded-md flex-1 p-4 align-center justify-center">
              <i className="fa fa-download" aria-hidden="true"></i>
              <p className="uppercase text-sm text-center">
                59865 <br /> Downloads
              </p>
            </div>
            <div className="flex flex-col bg-two text-white m-2 rounded-md flex-1 p-4 justify-center items-center">
              <i className="fa fa-download" aria-hidden="true"></i>
              <p className="uppercase text-sm">
                29852 <br /> likes
              </p>
            </div>
            <div className="flex flex-col bg-two text-white m-2 rounded-md flex-1 p-4 justify-center items-center text-center">
              <i className="fa fa-download" aria-hidden="true"></i>
              <p className="uppercase inline text-sm">
                1500 <br /> 5 star rating
              </p>
            </div>
          </div>
        </div>
      {/* </Slide> */}
      <div className="flex basis-[10%]"></div>
      {/* <Slide right> */}
        <div className="flex basis-[50%] justify-center" data-aos="slide-left">
          <img src={phone} alt="" />
        </div>
      {/* </Slide> */}
    </div>
  )
}

export default DownloadApp
