import React from 'react'
// import Fade from 'react-reveal/Fade'

import who from '../assets/images/who.png'
import bulb from '../assets/images/bulb.png'
import values from '../assets/images/values.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const WhoAreWeSection = () => {
  return (
    <div className="font-body  md:w-auto m-4">
      <div className="flex flex-col md:flex-row h-full justify-center items-center ">
        <div className="md: md:w-[65%] flex items-start px-4 justify-center flex-col text-lite  h-[50vh]">
          <div className="w-full ">
            <h2 className="font-semibold w-full font-subhead text-center md:text-left">
              Who are we?
            </h2>
          </div>
          <p className="">
            Synergistic Solutions is a consultancy firm that will help you get
            your company certified. We will give solutions for your Quality
            Management System, environmental, and safety initiatives, whether
            ISO 9001:2015 or ISO 14001.
          </p>
          <br />
          <p className="cursor-pointer">
            Read more
            <svg
              className="w-[12px] inline-flex mx-2 fill-lite animate-bounce"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M374.6 310.6l-160 160C208.4 476.9 200.2 480 192 480s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 370.8V64c0-17.69 14.33-31.1 31.1-31.1S224 46.31 224 64v306.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0S387.1 298.1 374.6 310.6z" />
            </svg>
          </p>
        </div>
        <div className=" my-8 w-full md:w-[25rem]  flex justify-center items-center">
          {/* <Fade right> */}
            <div className="w-[80%] drop-shadow-2xl" data-aos="fade-left">
              <LazyLoadImage src={who} alt="" />
            </div>
          {/* </Fade> */}
        </div>
      </div>
      {/*  */}
      {/*  */}
      {/*  */}
      <div className="md:mt-24 flex flex-col-reverse md:flex-row h-full justify-center items-center ">
        <div className=" my-8 w-full md:w-[25rem]  flex justify-center items-center">
          {/* <Fade left> */}
            <div className="w-[80%] drop-shadow-2xl" data-aos="fade-right">
              <LazyLoadImage src={bulb} alt="" />
            </div>
          {/* </Fade> */}
        </div>
        <div className="md:w-[65%] flex items-start px-4 justify-center flex-col text-lite  h-[50vh]">
          <div className="w-full ">
            <h2 className="font-semibold w-full font-subhead text-center md:text-left ">
              Our Mission and Vision
            </h2>
          </div>

          <p className="">
            To consistently improve our customers' capacity to conduct their
            businesses efficiently, we must continually improve the
            effectiveness of the Quality Management System, including any
            applicable legislative and regulatory obligations.
          </p>
          <br />
          <p className="cursor-pointer">
            Read more
            <svg
              className="w-[12px] inline-flex mx-2 fill-lite animate-bounce"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M374.6 310.6l-160 160C208.4 476.9 200.2 480 192 480s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 370.8V64c0-17.69 14.33-31.1 31.1-31.1S224 46.31 224 64v306.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0S387.1 298.1 374.6 310.6z" />
            </svg>
          </p>
        </div>
      </div>
      {/*  */}
      {/*  */}
      {/*  */}
      <div className="flex flex-col md:flex-row h-full justify-center items-center ">
        <div className="md: md:w-[65%] flex items-start px-4 justify-center flex-col text-lite  h-[50vh]">
          <div className="w-full">
            <h2 className="font-semibold w-full font-subhead text-center md:text-left">
              Our Values
            </h2>
          </div>

          <p className="">
            The professionalism and approach you'd expect from the world's
            leading consulting organizations. With the special attention and
            care you deserve <br />
            <strong>That is the Synergistic Solution.</strong>
          </p>
          <br />
          <p className="cursor-pointer">
            Read more
            <svg
              className="w-[12px] inline-flex mx-2 fill-lite animate-bounce"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M374.6 310.6l-160 160C208.4 476.9 200.2 480 192 480s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 370.8V64c0-17.69 14.33-31.1 31.1-31.1S224 46.31 224 64v306.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0S387.1 298.1 374.6 310.6z" />
            </svg>
          </p>
        </div>
        <div className=" my-8 w-full md:w-[25rem]  flex justify-center items-center">
          {/* <Fade right> */}
            <div className="w-[80%] drop-shadow-2xl" data-aos="fade-left">
              <LazyLoadImage src={values} alt="" />
            </div>
          {/* </Fade> */}
        </div>
      </div>
    </div>
  )
}

export default WhoAreWeSection
