import React, { useState } from 'react'
import '../App.css'
// import Fade from 'react-reveal/Fade'

import Pic1 from '../assets/images/service1.png'
import Pic2 from '../assets/images/service2.png'
import Pic3 from '../assets/images/service3.png'
import Modal from 'react-modal/lib/components/Modal'
import ReactPlayer from 'react-player'
import trucks from '../assets/videos/trucks.mp4'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'

const FrontComponent = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  function openModal() {
    setModalIsOpen(true)
  }
  function closeModal() {
    setModalIsOpen(false)
  }
  const modalStyles = {
    content: {
      margin: '2rem'
    }
  }
  return (
    <div className="flex flex-col md:flex-row text-lite ">
      <div className="flex flex-col justify-center mb-8 md:mb-0 md:pl-12 items-center  md:pr-0 sm:pr-6 basis-[50%]" data-aos="fade-right">
        <h1 className="font-head text-center md:text-left text-5xl sm:text-6xl md:text-7xl lg:text-8xl mr-2 md:pl-12 text-lite leading-tight">
          SYNERGISTIC <br /> SOLUTIONS.
        </h1>
        <br />
        <div className="uppercase text-lite flex flex-col md:flex-row text-lg mt-8 w-max">
          <Link to="/contactus">
          <button className="style-1 font-body mt-4 rounded-tr-2xl md:mx-4 inline-flex text-base justify-center align-middle sm:w-72 md:w-36 lg:w-48 lg:h-16  h-12">
            Get a Quote
            <svg
              className="fill-lite w-[15px] mx-2 animate-pulse"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z" />
            </svg>
          </button>
          </Link>
          <Link to="/aboutus">
          <button className="style-1 font-body mt-4 md:mx-4 rounded-tr-2xl  inline-flex text-base justify-center align-middle sm:w-72 md:w-36 lg:w-48 lg:h-16 h-12">
            Learn More
          </button>
          </Link>
        </div>
      </div>
      {/* md:mt-20 md:py-8 px-10 md:mr-4 mr-4*/}
      <div className=" w-full md:w-full flex justify-end items-center basis-[50%]" data-aos="fade-left">
        <div className="h-full min-w-[20rem] lg:max-w-[100%]  align-center text-center relative grid justify-items-center items-center">
          {/* <Fade right> */}
            <div className="wrap ">
              <div className="grid grid-rows-5 grid-cols-5">
                <LazyLoadImage
                  src={Pic2}
                  alt=""
                  className="row-start-1 row-end-4 col-start-2 col-end-6 z-10 shadow-xl"
                />
                <div className="relative row-start-2 z-20 row-end-5 col-start-1 col-end-6">
                  <LazyLoadImage src={Pic3} alt="" className="" />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    onClick={() => openModal()}
                    className="z-20 shadow-xl absolute w-12 transition-all duration-300 fill-two top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] cursor-pointer hover:fill-one hover:scale-150 "
                  >
                    <path d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z" />
                  </svg>
                </div>
                <LazyLoadImage
                  src={Pic1}
                  alt=""
                  className="row-start-3 shadow-xl row-end-5 col-start-2 col-end-6 z-10"
                />
              </div>
            </div>
          {/* </Fade> */}
        </div>
      </div>
      <div className="z-50  flex relative">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={modalStyles}
          className="Modal"
          overlayClassName="Overlay"
        >
          <button
            className="absolute z-50 -right-8 md:top-4 md:right-0"
            onClick={() => closeModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              className="w-4 fill-red-500 flex justify-end items-end"
            >
              <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
            </svg>
          </button>

          <ReactPlayer
            url={trucks}
            width="100%"
            height="100%"
            // className="h-max md:h-full"
            controls={true}
            playing={true}
          />
        </Modal>
      </div>
    </div>
  )
}

export default FrontComponent
